/*!
 * Style Name: Editorial Starter Styles
 * Description: Custom styles for Upstatement Editorial Starter Theme
 * Author: Upstatement
 * Version: 0.1.0
 */
/**
 * These are single-use mixins that contain base global
 * styles for both the front-end of the site and in the editor.
 * See:
 * static/scss/partials/global/_base.scss
 * src/Blocks/editor.scss (line 65)
 */
/* base
================================================================= */
/* Box model
----------------------------------------------------------------- */
*,
*::before,
*::after {
  box-sizing: border-box;
  color: var(--c-text);
}

/* add selections colors
----------------------------------------------------------------- */
* {
  /* stylelint-disable */
  /* stylelint-enable */
}
* ::-moz-selection {
  background: var(--c-text);
  color: var(--c-bg);
  text-shadow: none;
}
* ::selection {
  background: var(--c-text);
  color: var(--c-bg);
  text-shadow: none;
}

/* HTML5 display definitions
----------------------------------------------------------------- */
/* Correct `block` display not defined in IE 6/7/8/9 and Firefox 3. */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

/* Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3. */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  max-width: 100%;
  *zoom: 1;
}

/* Prevent modern browsers from displaying `audio` without controls. Remove excess height in iOS 5 devices. */
audio:not([controls]) {
  display: none;
  height: 0;
}

/* Address styling not present in IE 7/8/9, Firefox 3, and Safari 4. Known issue:no IE 6 support. */
[hidden] {
  display: none !important;
}

/* Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11. */
figure {
  margin: 25px 0;
}

/* Base
----------------------------------------------------------------- */
/* Address `font-family` inconsistency between `textarea` and other form elements. */
button,
input,
select,
textarea {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
  font-family: inherit;
}

section {
  margin: 0;
}

/* Embedded content
----------------------------------------------------------------- */
/* 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 2. Improve image quality when scaled in IE 7. */
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  /* stylelint-disable-line property-no-vendor-prefix */
}

img,
a img {
  height: auto;
  width: 100%;
}

object,
embed {
  height: auto;
  max-width: 100%;
}

/* Correct overflow displayed oddly in IE 9. */
svg:not(:root) {
  overflow: hidden;
}

.svg-filters {
  position: fixed;
  top: 100%;
  left: 100%;
  height: 0;
  width: 0;
}

.page-inner {
  padding-top: 100px;
}
@media (min-width: 600px) and (max-width: 1023px) {
  .page-inner {
    padding-top: calc(100px + (200 - 100) * ( 100vw - 600px ) / (1024 - 600));
  }
}
@media (min-width: 1024px) {
  .page-inner {
    padding-top: 200px;
  }
}

.skip-to-content {
  top: 0;
  color: var(--c-bg);
  height: 1px;
  left: -999px;
  overflow: hidden;
  position: absolute;
  text-decoration: none;
  width: 1px;
  z-index: -100;
}
.admin-bar .skip-to-content {
  top: calc(0px + var(--wp-admin--admin-bar--height));
}
.skip-to-content:focus, .skip-to-content:active {
  background-color: var(--c-text);
  height: auto;
  left: 0;
  overflow: auto;
  padding: 16px 20px;
  width: auto;
  z-index: 101;
}

#content {
  -webkit-box-flex: 1;
          flex-grow: 1;
  outline: 0;
}

.hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.hidden.focusable:active, .hidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/* 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using `em` units.
2. Prevent iOS text size adjust after orientation change, without disabling user zoom.
3. Normalize font rendering in Firefox */
html {
  /* stylelint-enable */
  font-family: inherit;
  font-size: 100%;
  /* 1 */
  /* stylelint-disable */
  -moz-osx-font-smoothing: #666666;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/* Address margins handled incorrectly in IE 6/7. */
body {
  font-size: 16px;
  color: var(--c-text);
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  background-color: var(--c-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
@media (min-width: 400px) and (max-width: 1399px) {
  body {
    font-size: calc(16px + (20 - 16) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  body {
    font-size: 20px;
  }
}

*:first-child {
  margin-top: 0;
}

a:active,
a:hover {
  text-decoration: none;
}

.base-body a {
  text-decoration: none;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

button {
  cursor: pointer;
}

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

b,
strong {
  font-weight: 700;
}

h1,
.h1 {
  font-size: 28px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 400px) and (max-width: 1399px) {
  h1,
.h1 {
    font-size: calc(28px + (60 - 28) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  h1,
.h1 {
    font-size: 60px;
  }
}

h2,
.h2 {
  font-size: 24px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 400px) and (max-width: 1399px) {
  h2,
.h2 {
    font-size: calc(24px + (45 - 24) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  h2,
.h2 {
    font-size: 45px;
  }
}

h3,
.h3 {
  font-size: 20px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 400px) and (max-width: 1399px) {
  h3,
.h3 {
    font-size: calc(20px + (36 - 20) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  h3,
.h3 {
    font-size: 36px;
  }
}

h4,
.h4 {
  font-size: 16px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 400px) and (max-width: 1399px) {
  h4,
.h4 {
    font-size: calc(16px + (28 - 16) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  h4,
.h4 {
    font-size: 28px;
  }
}

h5,
.h5 {
  font-size: 16px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 400px) and (max-width: 1399px) {
  h5,
.h5 {
    font-size: calc(16px + (20 - 16) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  h5,
.h5 {
    font-size: 20px;
  }
}

h6,
.h6 {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
}
@media (min-width: 400px) and (max-width: 1399px) {
  h6,
.h6 {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  h6,
.h6 {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 18px 0 0;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-size: 0.75em;
  font-weight: normal;
}

blockquote {
  margin: 1em 40px;
}

dfn {
  font-style: italic;
}

hr {
  /* stylelint-disable-line property-no-vendor-prefix */
  box-sizing: content-box;
  height: 0;
}

mark {
  background-color: var(--c-bg);
  color: var(--c-text);
}

p,
pre {
  margin: 18px 0 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace;
  font-size: 16px;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q::before,
q::after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

s {
  background: linear-gradient(180deg, transparent 55%, currentColor 55%, currentColor calc(55% + 1px), transparent calc(55% + 1px));
  text-decoration: none;
}

[hidden] {
  display: none !important;
}

dl,
menu,
ol,
ul {
  margin: 18px 0 0;
}

dd {
  margin: 0 0 0 40px;
}

menu,
ol,
ul {
  padding: 0 0 0 40px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

nav li a {
  text-decoration: none;
}

ul ul,
ul ol,
ol ul,
ol ol {
  margin: 0;
}

.highlight {
  background-color: var(--c-spot);
}

hr {
  margin-top: 36px;
  margin-bottom: 36px;
  background-color: var(--c-text);
  border: 0;
  display: block;
  height: 1px;
  padding: 0;
}
@media (min-width: 768px) {
  hr {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@font-face {
  font-display: auto;
  font-family: "Untitled Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/static/fonts/untitled-sans-light.woff2") format("woff2");
}
@font-face {
  font-display: auto;
  font-family: "Untitled Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/static/fonts/untitled-sans-regular.woff2") format("woff2");
}
@font-face {
  font-display: auto;
  font-family: "Untitled Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/static/fonts/untitled-sans-bold.woff2") format("woff2");
}
.grid {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-left: 15px;
  padding-right: 15px;
  display: none;
  pointer-events: none;
  z-index: 9999;
}
@media (min-width: 768px) {
  .grid {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.show-grid .grid {
  display: block;
}

.grid-inner {
  margin-left: -10px;
  margin-right: -10px;
  min-height: 100vh;
  pointer-events: none;
}
@media (min-width: 768px) {
  .grid-inner {
    background-color: transparent;
    margin-left: -10px;
    margin-right: -10px;
  }
}
.grid-inner .grid-column {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  pointer-events: none;
  width: 25%;
}
@media (min-width: 768px) {
  .grid-inner .grid-column {
    display: block;
    width: 8.3333333333%;
  }
}
.grid-inner .grid-column:nth-child(1), .grid-inner .grid-column:nth-child(2), .grid-inner .grid-column:nth-child(3), .grid-inner .grid-column:nth-child(4) {
  display: block;
}
.grid-inner .grid-column--inner {
  background-color: rgba(255, 0, 0, 0.25);
  display: block;
  min-height: 100vh;
}

:root,
[data-theme=dark] {
  --c-bg: #2b342b;
  --c-spot: #05ff00;
  --c-text: #ffffff;
}

[data-theme=purple] {
  --c-bg: #282828;
  --c-spot: #e8ff5a;
  --c-text: #A3A3A3;
}

[data-theme=yellow] {
  --c-bg: #e8ff5a;
  --c-spot: #ff0000;
  --c-text: #000000;
}

.footer {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 30px;
  margin-top: 60px;
}
@media (min-width: 768px) {
  .footer {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 400px) and (max-width: 1399px) {
  .footer {
    margin-top: calc(60px + (120 - 60) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .footer {
    margin-top: 120px;
  }
}
@media (min-width: 768px) {
  .footer {
    padding-bottom: 40px;
  }
}

.footer__inner {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  border-top: 1px solid;
  padding-top: 8px;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .footer__inner {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .footer__inner {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .footer__inner {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
}

.footer__links {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
}

.footer__link {
  display: inline-block;
}
.footer__link:not(:first-child) {
  margin-left: 12px;
}
.footer__link a {
  cursor: pointer;
}
.footer__link a:hover, .footer__link a:focus {
  box-shadow: inset 0 -1px var(--c-spot);
}
.footer__link a:active {
  box-shadow: inset 0 -1px var(--c-text);
  color: var(--c-text);
}

:root {
  --swiper-theme-color: red;
}

.swiper-container {
  list-style: none;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  padding: 0;
  position: relative;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.swiper-wrapper {
  display: -webkit-box;
  display: flex;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  width: 100%;
  z-index: 1;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  flex-wrap: wrap;
}

.swiper-container-free-mode > .swiper-wrapper {
  margin: 0 auto;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

.swiper-container-pointer-events {
  touch-action: pan-y;
}
.swiper-container-pointer-events.swiper-container-vertical {
  touch-action: pan-x;
}

.swiper-slide {
  flex-shrink: 0;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  width: 100%;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
          align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}

/* 3D Effects */
.swiper-container-3d {
  -webkit-perspective: 1200px;
          perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

/* CSS Mode */
.swiper-container-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
}
.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
}

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  -ms-scroll-snap-type: y mandatory;
      scroll-snap-type: y mandatory;
}

@media (min-width: 768px) {
  .topper {
    margin-left: -10px;
    margin-right: -10px;
    display: -webkit-box;
    display: flex;
  }
}

@media (min-width: 768px) {
  .topper__info {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .topper__info {
    width: 25%;
  }
}

.topper__info__name {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  color: var(--c-spot);
  margin-top: 0;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .topper__info__name {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .topper__info__name {
    font-size: 16px;
  }
}

.topper__info__title {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  margin-top: 0;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .topper__info__title {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .topper__info__title {
    font-size: 16px;
  }
}

.topper__about {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  margin-top: 1.55em;
}
@media (min-width: 768px) {
  .topper__about {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 400px) and (max-width: 1399px) {
  .topper__about {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .topper__about {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .topper__about {
    -webkit-column-count: 2;
       -moz-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    width: 50%;
  }
  .topper__about p {
    -webkit-column-break-inside: avoid;
       -moz-column-break-inside: avoid;
            break-inside: avoid;
  }
}
.topper__about a {
  cursor: pointer;
  color: inherit;
}
.topper__about a:hover, .topper__about a:focus {
  box-shadow: inset 0 -1px var(--c-spot);
}
.topper__about a:active {
  box-shadow: inset 0 -1px var(--c-text);
  color: var(--c-text);
}

.clients {
  margin-top: 60px;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .clients {
    margin-top: calc(60px + (200 - 60) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .clients {
    margin-top: 200px;
  }
}

.clients__h {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  border-top: 1px solid var(--c-text);
  padding-top: 8px;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .clients__h {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .clients__h {
    font-size: 16px;
  }
}

.clients__list-width {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .clients__list-width {
    margin-left: -40px;
    margin-right: -40px;
  }
}

@-webkit-keyframes marquee {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@keyframes marquee {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}
@-webkit-keyframes marquee-out {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@keyframes marquee-out {
  from {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
.clients__list__part {
  -webkit-animation: marquee 35s linear infinite;
          animation: marquee 35s linear infinite;
  display: inline-block;
  will-change: transform;
}

.clients__list {
  display: inline-block;
  display: -webkit-box;
  display: flex;
  font-family: Untitled Sans, sans-serif;
  font-size: 10vw;
  font-weight: 300;
  letter-spacing: -0.03em;
  line-height: 1.1;
  list-style: none;
  margin-top: 0;
  padding-left: 0;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .clients__list {
    font-size: 7vw;
  }
}
.clients__list:first-child {
  margin-top: 24px;
}
@media (min-width: 768px) {
  .clients__list:first-child {
    margin-top: 60px;
  }
}
.clients__list:nth-child(2) .clients__list__part {
  -webkit-animation: marquee-out 40s linear infinite;
          animation: marquee-out 40s linear infinite;
}
.clients__list .clients__item {
  padding-left: 15px;
  padding-right: 15px;
  color: var(--c-text);
  display: inline;
}
@media (min-width: 768px) {
  .clients__list .clients__item {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.clients__list a {
  color: currentColor;
  cursor: pointer;
}
.clients__list a:hover, .clients__list a:focus {
  color: var(--c-spot);
}
.clients__list a:active {
  color: var(--c-text);
}

:root {
  --mouse-x: 50%;
  --mouse-y: 50%;
}

.project__outer {
  display: none;
}
.hoverscroll .project__outer {
  display: block;
}

.projects {
  margin-top: 36px;
}

.project {
  display: block;
  position: relative;
}
.project .project__h__link {
  cursor: pointer;
}
.project:hover .project__h__link, .project:focus .project__h__link {
  box-shadow: inset 0 -1px var(--c-spot);
}
.project:active .project__h__link {
  box-shadow: inset 0 -1px var(--c-text);
  color: var(--c-text);
}
.hoverscroll .project {
  cursor: none;
}

.project__cursor {
  position: absolute;
  top: var(--mouse-y);
  left: var(--mouse-x);
  height: 100px;
  width: 100px;
  font-size: 11px;
  color: var(--c-bg);
  font-family: Untitled Sans, sans-serif;
  letter-spacing: 0.04em;
  line-height: 1.25;
  text-transform: uppercase;
  -webkit-box-align: center;
          align-items: center;
  background: #ffffff;
  border-radius: 50%;
  color: #000000;
  display: -webkit-box;
  display: flex;
  justify-content: space-around;
  mix-blend-mode: difference;
  opacity: 0;
  pointer-events: none;
  -webkit-transform: translate3d(-50%, -50%, 0) scale(0.5);
          transform: translate3d(-50%, -50%, 0) scale(0.5);
  z-index: 1;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .project__cursor {
    font-size: calc(11px + (13 - 11) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .project__cursor {
    font-size: 13px;
  }
}
.project__cursor b,
.project__cursor strong {
  font-weight: 700;
}
.project:hover .project__cursor {
  opacity: 1;
  position: fixed;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  -webkit-transition: opacity 0.3s ease, -webkit-transform 0.2s ease;
  transition: opacity 0.3s ease, -webkit-transform 0.2s ease;
  transition: opacity 0.3s ease, transform 0.2s ease;
  transition: opacity 0.3s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
}

.project__asset__mod {
  border-radius: 5px;
  line-height: 0;
  -webkit-mask-image: radial-gradient(#ffffff, #000000);
          mask-image: radial-gradient(#ffffff, #000000);
  overflow: hidden;
  position: relative;
}
@media (min-width: 768px) {
  .project__asset__mod {
    border-radius: 20px;
  }
}
.project__asset__mod::after {
  background-color: var(--c-text);
  content: "";
  display: block;
  opacity: 0.15;
  padding-top: 66.6666%;
  width: 100%;
}

.project__asset {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}
.project:hover .project__asset {
  opacity: 1;
}

.project__asset--video {
  position: absolute;
  top: 19.1%;
  right: auto;
  bottom: 0;
  left: 13.3%;
  border-radius: 1vw 1vw 0 0;
  display: none;
  height: 81%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
  width: 73.8%;
}
.show-video .project__asset--video {
  display: block;
}

.project__text-mod {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .project__text-mod {
    margin-left: -10px;
    margin-right: -10px;
    display: -webkit-box;
    display: flex;
  }
}

.project__h {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .project__h {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .project__h {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .project__h {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .project__h {
    width: 25%;
  }
}

.project__desc {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  margin-top: 8px;
  opacity: 0.6;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .project__desc {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .project__desc {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .project__desc {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .project__desc {
    margin-top: 0;
    min-height: 4.65em;
    width: 50%;
  }
}

.project__year {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  margin-top: 8px;
  opacity: 0.6;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .project__year {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .project__year {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .project__year {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .project__year {
    margin-top: 0;
    width: 25%;
  }
}

.project-carousel {
  display: none;
  overflow: hidden;
  padding: 0;
}
.carousel .project-carousel {
  display: block;
}
.project-carousel .project__asset {
  opacity: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.project-carousel .swiper-lazy-loaded.project__asset {
  opacity: 1;
}

.all-projects {
  margin-top: 60px;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .all-projects {
    margin-top: calc(60px + (200 - 60) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .all-projects {
    margin-top: 200px;
  }
}

.all-projects__labels {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  position: relative;
  display: none;
  list-style: none;
  padding-left: 0;
  padding-top: 8px;
}
@media (min-width: 768px) {
  .all-projects__labels {
    margin-left: -10px;
    margin-right: -10px;
    display: -webkit-box;
    display: flex;
  }
}
@media (min-width: 400px) and (max-width: 1399px) {
  .all-projects__labels {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .all-projects__labels {
    font-size: 16px;
  }
}
.all-projects__labels::before {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-top: 1px solid var(--c-text);
  content: "";
  display: block;
}
@media (min-width: 768px) {
  .all-projects__labels::before {
    left: 10px;
    right: 10px;
  }
}

@media (min-width: 768px) {
  .all-projects__label {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .all-projects__label:nth-child(1) {
    width: 16.6666666667%;
  }
}
@media (min-width: 1024px) {
  .all-projects__label:nth-child(1) {
    width: 8.3333333333%;
  }
}
@media (min-width: 768px) {
  .all-projects__label:nth-child(2) {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .all-projects__label:nth-child(2) {
    width: 41.6666666667%;
  }
}
@media (min-width: 768px) {
  .all-projects__label:nth-child(3) {
    width: 25%;
  }
}
@media (min-width: 1024px) {
  .all-projects__label:nth-child(3) {
    width: 41.6666666667%;
  }
}
.all-projects__label:nth-child(4) {
  width: 8.3333333333%;
}

.all-projects__list {
  list-style: none;
  padding-left: 0;
}
@media (min-width: 768px) {
  .all-projects__list {
    margin-top: 36px;
  }
}

.all-projects__project {
  margin-top: 5px;
}
@media (min-width: 768px) {
  .all-projects__project {
    margin-top: 18px;
  }
}

@media (min-width: 768px) {
  .all-projects__project__no-link,
.all-projects__project__link {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media (min-width: 768px) {
  .all-projects__project__no-link,
.all-projects__project__link {
    -webkit-box-align: end;
            align-items: flex-end;
    display: -webkit-box;
    display: flex;
  }
}
.all-projects__project__no-link > span,
.all-projects__project__link > span {
  color: inherit;
}
@media (min-width: 768px) {
  .all-projects__project__no-link > span,
.all-projects__project__link > span {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .all-projects__project__no-link > span:nth-child(1),
.all-projects__project__link > span:nth-child(1) {
    width: 16.6666666667%;
  }
}
@media (min-width: 1024px) {
  .all-projects__project__no-link > span:nth-child(1),
.all-projects__project__link > span:nth-child(1) {
    width: 8.3333333333%;
  }
}
@media (min-width: 768px) {
  .all-projects__project__no-link > span:nth-child(2),
.all-projects__project__link > span:nth-child(2) {
    width: 50%;
  }
}
@media (min-width: 1024px) {
  .all-projects__project__no-link > span:nth-child(2),
.all-projects__project__link > span:nth-child(2) {
    width: 41.6666666667%;
  }
}
@media (min-width: 768px) {
  .all-projects__project__no-link > span:nth-child(3),
.all-projects__project__link > span:nth-child(3) {
    width: 25%;
  }
}
@media (min-width: 1024px) {
  .all-projects__project__no-link > span:nth-child(3),
.all-projects__project__link > span:nth-child(3) {
    width: 41.6666666667%;
  }
}
@media (min-width: 768px) {
  .all-projects__project__no-link > span:nth-child(4),
.all-projects__project__link > span:nth-child(4) {
    width: 8.3333333333%;
  }
}

.all-projects__year-group {
  border-top: 1px solid;
}
@media (min-width: 768px) {
  .all-projects__year-group {
    display: none;
  }
}
.all-projects__year-group:not(:first-child) {
  margin-top: 36px;
}

.all-projects__project__link:hover, .all-projects__project__link:focus {
  color: var(--c-spot);
}

.all-projects__project__name {
  display: block;
  margin-top: 12px;
}
@media (min-width: 768px) {
  .all-projects__project__name {
    margin-top: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.all-projects__project__year {
  display: none;
  font-variant-numeric: tabular-nums;
}
@media (min-width: 768px) {
  .all-projects__project__year {
    display: block;
  }
}

.all-projects__project__name,
.all-projects__project__year {
  font-size: 20px;
  line-height: 1.2em;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .all-projects__project__name,
.all-projects__project__year {
    font-size: calc(20px + (30 - 20) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .all-projects__project__name,
.all-projects__project__year {
    font-size: 30px;
  }
}

.all-projects__project__role {
  font-size: 14px;
  color: inherit;
  font-family: Untitled Sans, sans-serif;
  font-weight: 400;
  line-height: 1.55;
  display: block;
}
@media (min-width: 400px) and (max-width: 1399px) {
  .all-projects__project__role {
    font-size: calc(14px + (16 - 14) * ( 100vw - 400px ) / (1400 - 400));
  }
}
@media (min-width: 1400px) {
  .all-projects__project__role {
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  .all-projects__project__role {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.all-projects__project__circle {
  display: none;
}
@media (min-width: 768px) {
  .all-projects__project__circle {
    display: block;
  }
}
.all-projects__project__circle::after {
  height: 18px;
  width: 18px;
  background-color: currentColor;
  border: 1px solid;
  border-radius: 50%;
  color: inherit;
  content: "";
  display: block;
}
.all-projects__project__no-link .all-projects__project__circle::after {
  background-color: transparent;
}

.home__outer {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media (min-width: 768px) {
  .home__outer {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (min-width: 768px) {
  .home__outer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}